import React from "react";
import { graphql } from "gatsby";
import ClientsTemplate from "src/templates/clients";

export const query = graphql`
	query ClientsQuery {
		page: sanityClients(_id: { regex: "/(drafts.|)clients/" }) {
			content: _rawContent(resolveReferences: { maxDepth: 8 })
		}
	}
`;

const IndexPage = (props: any) => {
	const { location } = props;
	const content = props.data.page.content;
	return <ClientsTemplate location={location} data={content} />;
};

export default IndexPage;
